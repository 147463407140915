import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/logo.svg';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <header className={`main-header ${isMenuOpen ? 'menu-open' : ''}`}>
      {/* Logo Section */}
      <div className="main-header-logo-container">
        <Link to="/">
          <img src={logo} alt="Logo" className="main-header-logo" />
        </Link>
      </div>

      {/* Navigation Links */}
      <nav
        ref={menuRef}
        className={`main-header-nav-links ${isMenuOpen ? 'active' : ''}`}
      >
        <Link to="/services" className="main-header-nav-link">Services</Link>
        <Link to="/contact" className="main-header-nav-link">Contact</Link>
        <Link to="/financing" className="main-header-nav-link">Financing</Link>
        <Link to="/about-us" className="main-header-nav-link">About Us</Link>

        {/* Mobile Only Buttons */}
        {isMenuOpen && (
          <div className="main-header-dropdown-buttons">
            <button
              className="main-header-button main-header-button-black"
              onClick={() => navigate('/quote-tool')}
            >
              Quote
            </button>
            <a
              href="https://client.aigencyone.com"
              className="main-header-button main-header-button-gradient"
              target="_blank"
              rel="noopener noreferrer"
            >
              Login
            </a>
          </div>
        )}
      </nav>

      {/* Desktop Only Buttons */}
      {!isMenuOpen && (
        <div className="main-header-button-container">
          <button
            className="main-header-button main-header-button-black"
            onClick={() => navigate('/quote-tool')}
          >
            Quote
          </button>
          <a
            href="https://client.aigencyone.com"
            className="main-header-button main-header-button-gradient"
            target="_blank"
            rel="noopener noreferrer"
          >
            Login
          </a>
        </div>
      )}

      {/* Hamburger Menu */}
      <div
        className="main-header-hamburger-menu"
        onClick={toggleMenu}
        aria-label="Toggle navigation menu"
      >
        <span className="main-header-bar"></span>
        <span className="main-header-bar"></span>
        <span className="main-header-bar"></span>
      </div>
    </header>
  );
};

export default Header;
