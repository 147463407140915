import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage"; // Adjust path as needed
import Products from "./components/Products/Products"; // Adjust path as needed
import Services from "./components/Services/Services"; // Adjust path as needed
import ContactUs from "./components/Contact Us/ContactUs"; // Adjust path as needed
import Financing from "./components/Financing/Financing";
import QuoteTool from "./components/Quoting/QuoteTool";
import AboutUs from "./components/AboutUs/AboutUs"; // Adjust path as needed
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Pricing from "./components/Quoting/Pricing";
import ConsultationRequest from "./components/LandingPage/ConsultationRequest";
import TermsandConditions from "./components/LandingPage/TermsandConditions";
import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/products" element={<Products />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/financing" element={<Financing />} />
          <Route path="/quote-tool" element={<QuoteTool />} /> 
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/consultation-request" element={<ConsultationRequest />} />
          <Route path="/terms-of-service" element={<TermsandConditions />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
