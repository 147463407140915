import React, { useState } from "react";
import "./ContactUs.css";
import Header from "../Global/Header";
import Footer from "../Global/Footer";
import { db } from "../../firebase/firebaseConfig"; // Import Firestore instance
import { collection, addDoc, serverTimestamp } from "firebase/firestore"; // Import Firestore methods

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Add the form data to the Firestore "contacts" collection
      await addDoc(collection(db, "ContactUs"), {
        ...formData,
        createdAt: serverTimestamp(), // Add a timestamp field
      });
  
      setSuccessMessage("Message sent successfully!");
      setFormData({ name: "", email: "", subject: "", message: "" }); // Reset form fields
    } catch (error) {
      console.error("Error sending message: ", error);
      setErrorMessage("Something went wrong. Please try again.");
    }
  };
  
  return (
    <div className="contact-page">
      <Header />
      <div className="contact-hero-background"></div>
      <div className="contact-content-container">
        <div className="contact-form-container">
          <h1 className="contact-title">Contact Us</h1>
          <p className="contact-description">
            Have questions or need assistance? Fill out the form below, and we’ll get back to you as soon as possible.
          </p>

          {successMessage && <p className="success-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your full name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                placeholder="Enter the subject of your message"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="Write your message here"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-button">
              Send Message
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
