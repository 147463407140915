import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <header className="privacy-header">
                <h1>Privacy Policy</h1>
            </header>
            <section className="privacy-content">
                <p>
                    At AigencyOne, your privacy is of utmost importance to us. This Privacy Policy outlines
                    how we collect, use, and safeguard your information when you use our website or services.
                </p>

                <h2>1. Information We Collect</h2>
                <p>
                    We collect personal information you provide directly to us, such as your name, email address, 
                    phone number, and billing information. We may also collect information automatically, including 
                    your IP address, browser type, and usage data through cookies and other tracking technologies.
                </p>

                <h2>2. How We Use Your Information</h2>
                <p>
                    We use your information to:
                    <ul>
                        <li>Provide and improve our services</li>
                        <li>Process payments and manage subscriptions</li>
                        <li>Communicate with you about updates, promotions, or support</li>
                        <li>Ensure the security and functionality of our platform</li>
                    </ul>
                </p>

                <h2>3. Sharing Your Information</h2>
                <p>
                    We do not sell or rent your personal information. We may share your information with:
                    <ul>
                        <li>Trusted third-party service providers for operations like payment processing</li>
                        <li>Law enforcement or government agencies as required by law</li>
                        <li>Partners with whom we collaborate to improve our services</li>
                    </ul>
                </p>

                <h2>4. Data Security</h2>
                <p>
                    We implement robust security measures to protect your personal information. However, no online 
                    system is entirely secure, and we cannot guarantee complete protection against unauthorized access.
                </p>

                <h2>5. Your Rights</h2>
                <p>
                    Depending on your location, you may have rights such as:
                    <ul>
                        <li>Accessing your personal data</li>
                        <li>Requesting corrections or deletion of your data</li>
                        <li>Opting out of marketing communications</li>
                    </ul>
                    To exercise your rights, please contact us at privacy@aigencyone.com.
                </p>

                <h2>6. Cookies</h2>
                <p>
                    We use cookies to enhance your experience. You can manage your cookie preferences through your 
                    browser settings.
                </p>

                <h2>7. Changes to This Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time. Any changes will be posted on this page with 
                    an updated effective date.
                </p>

                <h2>Contact Us</h2>
                <p>
                    If you have questions about this Privacy Policy, please contact us at:
                    <br />
                    <strong>Email:</strong> marcus@aigencyone.com
                    <br />
                    <strong>Phone:</strong> 503-773-9168
                </p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
