import React, { useState } from "react";
import "./PreQualifyPopUp.css";

const PreQualifyPopUp = ({ closePopup }) => {
  const [businessAge, setBusinessAge] = useState("");
  const [businessDuration, setBusinessDuration] = useState("");
  const [monthlyRevenue, setMonthlyRevenue] = useState("");
  const [ficoScore, setFicoScore] = useState("");
  const [approvalCategory, setApprovalCategory] = useState("");
  const [approvalReason, setApprovalReason] = useState("");

  const calculateApprovalOdds = () => {
    let score = 0;
    let cappedCategory = "Excellent"; // Default category

    // Business age calculation
    if (businessAge === "no") {
      if (businessDuration !== "10-11mo") {
        // Automatically set "Low" if not in 10-11 months range
        setApprovalCategory("Low");
        setApprovalReason(
          "National Business Credit typically requires businesses to be operational for at least a full year. Businesses under this threshold are considered higher risk."
        );
        return;
      }
      score += 20;
    } else {
      score += 40;
    }

    // Monthly revenue calculation
    if (monthlyRevenue === "30k+") {
      score += 40;
    } else if (monthlyRevenue === "25k-30k") {
      score += 20;
      cappedCategory = "Good"; // Cap max approval category to "Good"
      setApprovalReason(
        "Businesses with monthly revenue between $25k-$30k are considered for approval but cannot qualify for the highest approval category."
      );
    } else {
      // Automatically set "Low" for under $25k
      setApprovalCategory("Low");
      setApprovalReason(
        "Monthly revenue under $25k does not meet the minimum threshold required for financing. Consider reapplying once your revenue is closer to $30k."
      );
      return;
    }

    // FICO score calculation
    if (ficoScore === "yes") {
      score += 20;
    } else {
      // Reduce category by one level for "No" FICO score
      if (cappedCategory === "Excellent") cappedCategory = "Good";
      else if (cappedCategory === "Good") cappedCategory = "Fair";
      else if (cappedCategory === "Fair") cappedCategory = "Low";

      setApprovalReason(
        "A FICO score below 600 may limit financing options. However, strong revenue and business history can still improve your chances."
      );
    }

    // Determine approval category
    if (score >= 80) {
      setApprovalCategory(cappedCategory); // Respect cap from revenue
      if (cappedCategory === "Excellent") {
        setApprovalReason(
          "Your business meets or exceeds the key requirements for financing, including strong revenue, FICO score, and business history."
        );
      }
    } else if (score >= 60) {
      setApprovalCategory("Good");
      setApprovalReason(
        "Your business meets most requirements, but slightly stronger metrics (such as revenue or business history) could further improve your chances."
      );
    } else if (score >= 40) {
      setApprovalCategory("Fair");
      setApprovalReason(
        "Your business meets the minimum requirements for financing, but improving key metrics (such as revenue or FICO score) could strengthen your application."
      );
    } else {
      setApprovalCategory("Low");
      setApprovalReason(
        "Your business does not meet the minimum requirements in key areas, such as time in operation or revenue. Consider reapplying when these metrics improve."
      );
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={closePopup}>
          ✖
        </button>
        <h2>Pre-Qualification Check</h2>

        {/* Business Age */}
        <div className="form-group">
          <label>Have you been in business for at least a year?</label>
          <div>
            <button
              className={`button ${businessAge === "yes" ? "selected" : ""}`}
              onClick={() => setBusinessAge("yes")}
            >
              Yes
            </button>
            <button
              className={`button ${businessAge === "no" ? "selected" : ""}`}
              onClick={() => setBusinessAge("no")}
            >
              No
            </button>
          </div>
        </div>

        {businessAge === "no" && (
          <div className="form-group">
            <label>How long have you been in business?</label>
            <select
              value={businessDuration}
              onChange={(e) => setBusinessDuration(e.target.value)}
            >
              <option value="">Select...</option>
              <option value="1-5mo">1-5 months</option>
              <option value="5-9mo">5-9 months</option>
              <option value="10-11mo">10-11 months</option>
            </select>
          </div>
        )}

        {/* Monthly Revenue */}
        <div className="form-group">
          <label>What is your monthly revenue?</label>
          <select
            value={monthlyRevenue}
            onChange={(e) => setMonthlyRevenue(e.target.value)}
          >
            <option value="">Select...</option>
            <option value="under25k">Under $25k</option>
            <option value="25k-30k">$25k - $30k</option>
            <option value="30k+">$30k+</option>
          </select>
        </div>

        {/* FICO Score */}
        <div className="form-group">
          <label>Do you have a FICO score of at least 600?</label>
          <div>
            <button
              className={`button ${ficoScore === "yes" ? "selected" : ""}`}
              onClick={() => setFicoScore("yes")}
            >
              Yes
            </button>
            <button
              className={`button ${ficoScore === "no" ? "selected" : ""}`}
              onClick={() => setFicoScore("no")}
            >
              No
            </button>
          </div>
        </div>

        {/* Check Approval Odds */}
        <button
          className="button button-primary"
          onClick={calculateApprovalOdds}
        >
          Check My PreQualification
        </button>

        {/* Display Approval Category */}
        {approvalCategory && (
          <div className="approval-odds">
            <h3>Approval Category: {approvalCategory}</h3>
            <p>{approvalReason}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PreQualifyPopUp;
