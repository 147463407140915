import React, { useState } from "react";
import "./Financing.css";
import Header from "../Global/Header";
import Footer from "../Global/Footer";
import "../styles/Buttons.css"; // Import the Buttons.css
import PreQualifyPopUp from "./PreQualifyPopUp"; // Import the Pop-Up component

const Financing = () => {
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setIsPopUpVisible(!isPopUpVisible);
  };

  return (
    <div className="financing-page">
      <div className="financing-hero-background"></div>
      <Header />
      <div className="financing-container">
        <header className="financing-header">
          <h1>Financing Opportunities with National Business Credit</h1>
          <div className="header-buttons">
            <button className="button button-primary" onClick={togglePopUp}>
              Pre-Qualify
            </button>
            <a
              href="https://www.nationalbusinesscapital.com/apply-now/?ref=10009678"
              target="_blank"
              rel="noopener noreferrer"
              className="button button-outline"
            >
              Apply Now
            </a>
          </div>
        </header>
        <section className="financing-content">
          {/* Side-by-Side Sections */}
          <div className="side-by-side-sections">
            <div className="text-section">
              <h2>Why Finance Your Technology with NBC?</h2>
              <p>
                Upgrading your business with cutting-edge technology can feel
                daunting due to upfront costs. That’s where National Business
                Credit (NBC) comes in. By leveraging their tailored financing
                options, you can access the tools you need without breaking
                your budget.
              </p>
              <p>
                Financing through NBC lets you invest in modern applications
                and software that streamline operations, boost productivity,
                and drive growth—all with flexible payment plans designed to
                fit your financial needs.
              </p>
              <p>
                Don’t let cost hold you back. Financing with NBC enables you to
                unlock the full potential of our custom technology solutions
                today while paying over time.
              </p>
            </div>

            <div className="text-section">
              <h2>Benefits of Financing Technology</h2>
              <ul>
                <li>Access custom-built software without upfront financial strain.</li>
                <li>Streamline operations and improve efficiency.</li>
                <li>Stay ahead of competitors with cutting-edge solutions.</li>
                <li>Spread costs over time to align with your cash flow.</li>
              </ul>
            </div>
          </div>

          {/* Full-Width Section */}
          <div className="text-section full-width">
            <h2>Transform Your Business Today</h2>
            <p>
              Take the first step toward innovation and success. Secure
              financing that aligns with your goals and brings your vision to
              life.
            </p>
            <a
              href="https://www.nationalbusinesscapital.com/partner/?ref=10009678"
              target="_blank"
              rel="noopener noreferrer"
              className="button button-primary"
            >
              Learn More
            </a>
          </div>
        </section>
      </div>
      {isPopUpVisible && <PreQualifyPopUp closePopup={togglePopUp} />}
      <Footer />
    </div>
  );
};

export default Financing;
