import React, { useState } from "react";
import "./Products.css";
import Header from "../Global/Header";
import Footer from "../Global/Footer";
import PMV1Logo from "../../assets/PMV1Logo.png"; // Placeholder image

// Complete product list with detailed descriptions
const products = [
  {
    id: 1,
    name: "PMV1",
    description: "A small business project management solution designed to streamline workflows.",
    details: {
      overview:
        "PMV1 is a lightweight project management platform tailored for small businesses. It helps streamline workflows by offering essential tools to track tasks, manage timelines, and collaborate effectively.",
      features: [
        "Task and timeline management",
        "Calendar integrations",
        "Real-time collaboration tools",
        "User-friendly dashboard for small teams",
      ],
      benefits: [
        "Boosts productivity for small teams",
        "Simple to use with minimal setup",
        "Affordable for small businesses",
      ],
    },
    tags: ["Project Management"],
    image: PMV1Logo,
  },
 
];

const Products = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [activeProduct, setActiveProduct] = useState(null);

  const filteredProducts = products.filter((product) => {
    const matchesSearch = product.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTag = selectedTag ? product.tags.includes(selectedTag) : true;
    return matchesSearch && matchesTag;
  });

  const closeModal = () => {
    setActiveProduct(null);
  };

  return (
    <div className="products-page">
      <Header />
      <div className="products-hero-background"></div>
<div className="products-content-container">
  <div className="products-header">
    <h1 className="products-title">Our Products</h1>
    <p className="products-description">
      Discover our pre-built business administration apps, designed to streamline operations and save you time.
    </p>
    <div className="products-header-buttons">
      <button className="products-signup-button">Sign Up Free Now</button>
    </div>
        </div>
        <div className="products-filter-container">
      <input
        type="text"
        placeholder="Search products..."
        className="products-search-bar"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
            <select
              className="products-tag-filter"
              onChange={(e) => setSelectedTag(e.target.value === "All" ? "" : e.target.value)}
            >
              <option value="All">All</option>
              {[
                "Project Management",
                "Client Management",
                "HR Management",
                "Finance Management",
                "Inventory Management",
                "Collaboration",
                "Marketing",
                "Learning System",
                "Onboarding",
              ].map((tag) => (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ))}
            </select>
          </div>
        <div className="products-container">
          {filteredProducts.map((product) => (
            <div className="products-card" key={product.id}>
              <img src={product.image} alt={product.name} className="products-image" />
              <h3 className="products-name">{product.name}</h3>
              <p className="products-description">{product.description}</p>
              <button
                className="products-learn-more"
                onClick={() => setActiveProduct(product)}
              >
                Learn More
              </button>
            </div>
          ))}
        </div>
        {activeProduct && (
          <div className="modal">
            <div className="modal-content">
              <button className="modal-close" onClick={closeModal}>✖</button>
              <h2>{activeProduct.name}</h2>
              <p>{activeProduct.details.overview}</p>
              <h3>Features:</h3>
              <ul>
                {activeProduct.details.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
              <h3>Benefits:</h3>
              <ul>
                {activeProduct.details.benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
              <button className="products-get-started">Get Started</button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Products;
