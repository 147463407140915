import React, { useState, useEffect, useMemo } from "react";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import "./QuoteTool.css";
import Header from "../Global/Header";
import Footer from "../Global/Footer";

const QuoteTool = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "", // Added phone number field
    appType: "",
    platform: [],
    industry: "",
    industryCustom: "",
    loginOption: [],
    socialPlatform: [],
    socialPlatformOther: "",
    paymentIntegration: [],
    paymentMethod: "",
    designComplexity: "",
    brandingAssistance: "",
    numberOfScreens: "",
    customScreenCount: "",
    chatFeature: "",
    callFeature: "",
    streamingFeature: "",
    socialMediaIntegration: "",
    pushNotifications: "",
    pushFrequency: "",
    geolocation: "",
    geofencingSupport: "",
    mapIntegration: "",
    mapType: "",
    mapFeatures: [],
    aiIntegration: "",
    aiType: "",
    aiUseCase: "",
    adminDashboard: "",
    subAdmins: "",
    subAdminCount: "",
    userRoles: "",
    reports: "",
    customAPIs: "",
    externalAppConnection: "",
    deploymentPlatform: [],
    ongoingSupport: "",
  });

  const [totalCost, setTotalCost] = useState(0);

  const featureCosts = useMemo(() => ({
    appType: { Mobile: 0, Web: 0, Both: 0, Unknown: 0 },
    platform: { iOS: 0, Android: 0, Both: 0, Unknown: 0 },
    loginOption: { Email: 200, Phone: 300, SocialMedia: 0, Unknown: 0 },
    socialPlatform: { Facebook: 300, Google: 200, Apple: 400, All: 900, Unknown: 0 },
    paymentIntegration: { Stripe: 350, PayPal: 350, Both: 700, Unknown: 0 },
    designComplexity: { Simple: 0, Moderate: 0, Custom: 0, Unknown: 0 },
    brandingAssistance: { Yes: 500, No: 0, Unknown: 0 },
    numberOfScreens: { "1-5": 700, "6-10": 900, "10+": 1300, Unknown: 0, Custom: 2000 },
    chatFeature: { OneToOne: 600, GroupChat: 700, Both: 800, Unknown: 0 },
    callFeature: { Audio: 400, Video: 500, Both: 900, No: 0, Unknown: 0 },
    streamingFeature: { Yes: 400, No: 0, Unknown: 0 },
    socialMediaIntegration: { Sharing: 300, Unknown: 0 },
    pushNotifications: { Yes: 400, No: 0, Unknown: 0 },
    geolocation: { Yes: 400, No: 0, Unknown: 0 },
    geofencingSupport: { Yes: 0, No: 0, Unknown: 0 },
    mapIntegration: { GoogleMaps: 400, OpenStreetMap: 400, Unknown: 0 },
    mapFeatures: { LocationSearch: 400, RouteMapping: 500, Geofencing: 400 },
    aiIntegration: { ChatAI: 700, ImageAI: 800, VideoAI: 1000, Unknown: 0 },
    adminDashboard: { Yes: 2000, No: 0, Unknown: 0 },
    subAdmins: { Yes: 1600, No: 0, Unknown: 0 },
    subAdminCount: { "1-2": 0, "3-5": 0, More: 0 },
    userRoles: { "1-2": 1000, "3-5": 2000, More: 3000, Unknown: 0 },
    reports: { Yes: 500, No: 0, Unknown: 0 },
    customAPIs: { Yes: 500, No: 0, Unknown: 0 },
    externalAppConnection: { Yes: 0, No: 0, Unknown: 0 },
    deploymentPlatform: { AppStore: 400, GooglePlay: 400, Web: 300, All: 1100, Unknown: 0 },
    ongoingSupport: { Yes: 250, No: 0 },
  }), []);
  

  const db = getFirestore();

  useEffect(() => {
    const calculateTotal = () => {
      let cost = 0;
      Object.keys(formData).forEach((key) => {
        const value = formData[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            cost += featureCosts[key][item] || 0;
          });
        } else if (featureCosts[key] && value) {
          cost += featureCosts[key][value] || 0;
        }
      });
      setTotalCost(cost);
    };
    calculateTotal();
  }, [formData, featureCosts]);
  

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => {
        const currentSelection = new Set(prevData[name]);
        if (currentSelection.has(value)) {
          currentSelection.delete(value);
        } else {
          currentSelection.add(value);
        }
        return { ...prevData, [name]: Array.from(currentSelection) };
      });
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const quoteDetails = { ...formData, totalCost };

    try {
      await addDoc(collection(db, "Quotes"), quoteDetails);
      alert("Quote submitted successfully! We will contact you shortly.");
      
      setFormData({
        name: "",
        email: "",
        phone: "",
        appType: "",
        platform: [],
        industry: "",
        industryCustom: "",
        loginOption: [],
        socialPlatform: [],
        socialPlatformOther: "",
        paymentIntegration: [],
        paymentMethod: "",
        designComplexity: "",
        brandingAssistance: "",
        numberOfScreens: "",
        customScreenCount: "",
        chatFeature: "",
        callFeature: "",
        streamingFeature: "",
        socialMediaIntegration: "",
        pushNotifications: "",
        pushFrequency: "",
        geolocation: "",
        geofencingSupport: "",
        mapIntegration: "",
        mapType: "",
        mapFeatures: [],
        aiIntegration: "",
        aiType: "",
        aiUseCase: "",
        adminDashboard: "",
        subAdmins: "",
        subAdminCount: "",
        userRoles: "",
        reports: "",
        customAPIs: "",
        externalAppConnection: "",
        deploymentPlatform: [],
        ongoingSupport: "",
      });
      setTotalCost(0);
    } catch (error) {
      console.error("Error submitting quote:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <Header/> 

    <div className="quote-tool-container">
      <h2>AigencyOne Quote Calculator</h2>
      <form className="quote-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Phone Number:</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>App Type:</label>
          <select name="appType" onChange={handleChange}>
            <option value="">Select</option>
            <option value="Mobile">Mobile</option>
            <option value="Web">Web</option>
            <option value="Both">Both</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        {(formData.appType === "Mobile" || formData.appType === "Both") && (
          <div className="form-group">
            <label>Platform:</label>
            {["iOS", "Android", "Both", "Unknown"].map((option) => (
              <div key={option}>
                <input
                  type="checkbox"
                  name="platform"
                  value={option}
                  onChange={handleChange}
                  checked={formData.platform.includes(option)}
                />
                <label>{option}</label>
              </div>
            ))}
          </div>
        )}

        <div className="form-group">
          <label>Industry:</label>
          <select name="industry" onChange={handleChange}>
            <option value="">Select</option>
            <option value="E-commerce">E-commerce</option>
            <option value="Social">Social</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Finance">Finance</option>
            <option value="Custom">Custom</option>
            <option value="Unknown">I don't know</option>
          </select>
          {formData.industry === "Custom" && (
            <input
              type="text"
              name="industryCustom"
              placeholder="Specify custom industry"
              value={formData.industryCustom}
              onChange={handleChange}
            />
          )}
        </div>

        <div className="form-group">
          <label>Login Option:</label>
          {["Email", "Phone", "SocialMedia", "Unknown"].map((option) => (
            <div key={option}>
              <input
                type="checkbox"
                name="loginOption"
                value={option}
                onChange={handleChange}
                checked={formData.loginOption.includes(option)}
              />
              <label>{option}</label>
            </div>
          ))}
        </div>

        {formData.loginOption.includes("SocialMedia") && (
          <div className="form-group">
            <label>Social Platforms:</label>
            {["Facebook", "Google", "Apple", "All", "Unknown"].map((option) => (
              <div key={option}>
                <input
                  type="checkbox"
                  name="socialPlatform"
                  value={option}
                  onChange={handleChange}
                  checked={formData.socialPlatform.includes(option)}
                />
                <label>{option}</label>
              </div>
            ))}
          </div>
        )}

        <div className="form-group">
          <label>Payment Integration:</label>
          {["Stripe", "PayPal", "Both", "Unknown"].map((option) => (
            <div key={option}>
              <input
                type="checkbox"
                name="paymentIntegration"
                value={option}
                onChange={handleChange}
                checked={formData.paymentIntegration.includes(option)}
              />
              <label>{option}</label>
            </div>
          ))}
        </div>

        <div className="form-group">
          <label>Design Complexity:</label>
          <select name="designComplexity" onChange={handleChange}>
            <option value="">Select</option>
            <option value="Simple">Simple</option>
            <option value="Moderate">Moderate</option>
            <option value="Custom">Custom</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        <div className="form-group">
          <label>Branding Assistance Needed?</label>
          <select name="brandingAssistance" onChange={handleChange}>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        <div className="form-group">
          <label>Number of Screens:</label>
          <select name="numberOfScreens" onChange={handleChange}>
            <option value="">Select</option>
            <option value="1-5">1-5</option>
            <option value="6-10">6-10</option>
            <option value="10+">10+</option>
            <option value="Custom">Custom</option>
            <option value="Unknown">I don't know</option>
          </select>
          {formData.numberOfScreens === "Custom" && (
            <input
              type="number"
              name="customScreenCount"
              placeholder="Specify number of screens"
              value={formData.customScreenCount}
              onChange={handleChange}
            />
          )}
        </div>

        <div className="form-group">
          <label>Chat Feature:</label>
          <select name="chatFeature" onChange={handleChange}>
            <option value="">Select</option>
            <option value="OneToOne">One-to-One</option>
            <option value="GroupChat">Group Chat</option>
            <option value="Both">Both</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        <div className="form-group">
          <label>Call Feature:</label>
          <select name="callFeature" onChange={handleChange}>
            <option value="">Select</option>
            <option value="Audio">Audio</option>
            <option value="Video">Video</option>
            <option value="Both">Both</option>
            <option value="No">No</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        <div className="form-group">
          <label>Live Streaming Feature:</label>
          <select name="streamingFeature" onChange={handleChange}>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        <div className="form-group">
          <label>Social Media Integration:</label>
          <select name="socialMediaIntegration" onChange={handleChange}>
            <option value="">Select</option>
            <option value="Sharing">Sharing</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        <div className="form-group">
          <label>Push Notifications:</label>
          <select name="pushNotifications" onChange={handleChange}>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Unknown">I don't know</option>
          </select>
          {formData.pushNotifications === "Yes" && (
            <input
              type="text"
              name="pushFrequency"
              placeholder="Frequency of Notifications"
              value={formData.pushFrequency}
              onChange={handleChange}
            />
          )}
        </div>

        <div className="form-group">
          <label>Geolocation:</label>
          <select name="geolocation" onChange={handleChange}>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        {formData.geolocation === "Yes" && (
          <div className="form-group">
            <label>Geofencing Support Needed?</label>
            <select name="geofencingSupport" onChange={handleChange}>
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Unknown">I don't know</option>
            </select>
          </div>
        )}

        <div className="form-group">
          <label>Map Integration:</label>
          <select name="mapIntegration" onChange={handleChange}>
            <option value="">Select</option>
            <option value="GoogleMaps">Google Maps</option>
            <option value="OpenStreetMap">OpenStreetMap</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        {formData.mapIntegration && (
          <div className="form-group">
            <label>Map Features:</label>
            {["LocationSearch", "RouteMapping", "Geofencing"].map((feature) => (
              <div key={feature}>
                <input
                  type="checkbox"
                  name="mapFeatures"
                  value={feature}
                  onChange={handleChange}
                  checked={formData.mapFeatures.includes(feature)}
                />
                <label>{feature}</label>
              </div>
            ))}
          </div>
        )}

        <div className="form-group">
          <label>AI Integration:</label>
          <select name="aiIntegration" onChange={handleChange}>
            <option value="">Select</option>
            <option value="ChatAI">Chat AI</option>
            <option value="ImageAI">Image AI</option>
            <option value="VideoAI">Video AI</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        <div className="form-group">
          <label>Admin Dashboard Needed?</label>
          <select name="adminDashboard" onChange={handleChange}>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        {formData.adminDashboard === "Yes" && (
          <div className="form-group">
            <label>Sub-Admins Needed?</label>
            <select name="subAdmins" onChange={handleChange}>
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Unknown">I don't know</option>
            </select>
          </div>
        )}

        <div className="form-group">
          <label>How Many User Roles?</label>
          <select name="userRoles" onChange={handleChange}>
            <option value="">Select</option>
            <option value="1-2">1-2</option>
            <option value="3-5">3-5</option>
            <option value="More">More</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        <div className="form-group">
          <label>Generate Reports?</label>
          <select name="reports" onChange={handleChange}>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        <div className="form-group">
          <label>Need Custom APIs?</label>
          <select name="customAPIs" onChange={handleChange}>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        <div className="form-group">
          <label>Connect to External Applications?</label>
          <select name="externalAppConnection" onChange={handleChange}>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Unknown">I don't know</option>
          </select>
        </div>

        <div className="form-group">
          <label>Deployment Platforms:</label>
          {["AppStore", "GooglePlay", "Web", "All", "Unknown"].map((option) => (
            <div key={option}>
              <input
                type="checkbox"
                name="deploymentPlatform"
                value={option}
                onChange={handleChange}
                checked={formData.deploymentPlatform.includes(option)}
              />
              <label>{option}</label>
            </div>
          ))}
        </div>

        <div className="form-group">
          <label>Ongoing Support Needed?</label>
          <select name="ongoingSupport" onChange={handleChange}>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className="quote-confirmation">
  <h3>Submit Your Quote Below</h3>
  <p>You will receive your quote in 1-2 business days.</p>
</div>
        <button type="submit" className="submit-btn">Submit Quote</button>

      </form>
    </div>
    <Footer />

    </>

  );
};

export default QuoteTool;
