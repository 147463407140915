import React from "react";
import "./Pricing.css";
import Header from "../Global/Header";
import Footer from "../Global/Footer";
import HolidayPricing from "../../assets/holidaypricing.png";

const Pricing = () => {
  return (
    <div className="pricing-page">
      <Header />
      <div className="pricing-hero-background">
        <div className="container">
          <div className="pricing-hero-content">
            <h1 className="pricing-title">Pricing</h1>
            <p className="pricing-description">
              Discover our flexible pricing plans tailored to meet your business
              needs. Choose the plan that works best for you and enjoy high-value
              service this holiday season.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <section className="pricing-section">
          <img
            src={HolidayPricing}
            alt="Holiday Pricing Flyer"
            className="holiday-pricing-image"
          />
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Pricing;