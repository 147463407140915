import React, { useState } from "react";
import "./ConsultationRequest.css";
import { db } from "../../firebase/firebaseConfig"; // Adjust the path as needed
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

const ConsultationRequest = ({ closePopup, isModal }) => {
  const [formData, setFormData] = useState({
    budget: "",
    companyName: "",
    companySize: "",
    companyWebsite: "",
    decisionRole: "",
    fullName: "",
    industry: "",
    location: "",
    phone: "",
    projectDescription: "",
    timeline: "",
    financingNeeded: "",
    financingDetails: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "ConsultationRequests"), {
        ...formData,
        submittedAt: serverTimestamp(),
      });
      setSuccess("Your consultation request has been submitted successfully.");
      setError("");
      // Clear the form
      setFormData({
        budget: "",
        companyName: "",
        companySize: "",
        companyWebsite: "",
        decisionRole: "",
        fullName: "",
        industry: "",
        location: "",
        phone: "",
        projectDescription: "",
        timeline: "",
        financingNeeded: "",
        financingDetails: "",
      });
    } catch (err) {
      console.error("Error submitting consultation request:", err);
      setError("There was an issue submitting your request. Please try again.");
      setSuccess("");
    }
  };

  return (
    <div className={isModal ? "popup-overlay" : "full-page-container"}>
      <div className="popup-content">
        <button
          className="close-button"
          onClick={() => {
            if (isModal) {
              closePopup();
            } else {
              // Navigate back if it's not a modal
              window.history.back();
            }
          }}
        >
          ✖
        </button>
        <h2>Request a Consultation</h2>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="fullName"
            placeholder="Your Full Name"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="companyName"
            placeholder="Company Name"
            value={formData.companyName}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="companyWebsite"
            placeholder="Business Website"
            value={formData.companyWebsite}
            onChange={handleChange}
          />
          <input
            type="text"
            name="industry"
            placeholder="Industry"
            value={formData.industry}
            onChange={handleChange}
            required
          />
          <select
            name="companySize"
            value={formData.companySize}
            onChange={handleChange}
            required
          >
            <option value="">Company Size</option>
            <option value="1-10">1-10 Employees</option>
            <option value="11-50">11-50 Employees</option>
            <option value="51-200">51-200 Employees</option>
            <option value="201-500">201-500 Employees</option>
            <option value="500+">500+ Employees</option>
          </select>
          <input
            type="text"
            name="location"
            placeholder="Business Location (Optional)"
            value={formData.location}
            onChange={handleChange}
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <textarea
            name="projectDescription"
            placeholder="Describe Your Project or Needs"
            value={formData.projectDescription}
            onChange={handleChange}
            required
          ></textarea>
          <select
            name="budget"
            value={formData.budget}
            onChange={handleChange}
            required
          >
            <option value="">Estimated Budget</option>
            <option value="<$5,000">Less than $5,000</option>
            <option value="$5,000-$10,000">$5,000-$10,000</option>
            <option value="$10,000-$50,000">$10,000-$50,000</option>
            <option value=">$50,000">More than $50,000</option>
          </select>
          <select
            name="timeline"
            value={formData.timeline}
            onChange={handleChange}
            required
          >
            <option value="">Expected Timeline</option>
            <option value="ASAP">ASAP</option>
            <option value="1-3 months">1-3 months</option>
            <option value="3-6 months">3-6 months</option>
            <option value="6+ months">6+ months</option>
          </select>
          <input
            type="text"
            name="decisionRole"
            placeholder="Your Role (CEO, CTO, etc.)"
            value={formData.decisionRole}
            onChange={handleChange}
            required
          />
          <div className="form-group">
            <label>Do you need financing?</label>
            <div>
              <button
                type="button"
                className={`button ${
                  formData.financingNeeded === "yes" ? "selected" : ""
                }`}
                onClick={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    financingNeeded: "yes",
                  }))
                }
              >
                Yes
              </button>
              <button
                type="button"
                className={`button ${
                  formData.financingNeeded === "no" ? "selected" : ""
                }`}
                onClick={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    financingNeeded: "no",
                  }))
                }
              >
                No
              </button>
            </div>
          </div>
          {formData.financingNeeded === "yes" && (
            <textarea
              name="financingDetails"
              placeholder="Please provide details about your financing needs."
              value={formData.financingDetails}
              onChange={handleChange}
              required
            ></textarea>
          )}
          <button type="submit" className="button button-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ConsultationRequest;
