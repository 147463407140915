import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import React Router's useNavigate hook
import "./LandingPage.css";
import Header from "../Global/Header";
import "../styles/Buttons.css"; // Centralized button styles
import logo from "../../assets/logo.svg"; // Import logo.svg for placeholders
import CCGLogo from "../../assets/CCGLogo.png"; // Import PNG as React component
import CustomAdminAppsIcon from "../../assets/CustomAdminAppsIcon.png"; // Import PNG as React component
import LandingPageUserPhoto from "../../assets/LandingPageUserPhoto.svg"; // Import logo.svg for placeholders
import { ReactComponent as LandingPageGraph } from "../../assets/LandingPageGraph.svg"; // Import SVG as React component
import PreBuiltAppsIcon from "../../assets/PreBuiltAppsIcon.png"; // Import PNG as React component
import SDVOSBIcon from "../../assets/SDVOSBIcon.png"; // Import PNG as React component
import Footer from "../Global/Footer";
import EforceLogo from "../../assets/EforceLogo.svg"; // Import PNG as React component
import GunHavenLogo from "../../assets/GunHavenLogo.jpeg"; // Import PNG as React component
import TestimonialBackground from "../../assets/TestimonialBackground.png"; // Import PNG as React component
import ConsultationRequest from "./ConsultationRequest";


const LandingPage = () => {
  const [activeFAQ, setActiveFAQ] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isConsultationVisible, setIsConsultationVisible] = useState(false);
  const navigate = useNavigate(); // React Router navigation function



  const toggleFAQ = (index) => {
    setActiveFAQ(activeFAQ === index ? null : index);
  };

  const logos = Array(8).fill(logo); // Fill an array with logo.svg for all placeholders

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? logos.length - 5 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === logos.length - 5 ? 0 : prevIndex + 1
    );
  };

  const toggleConsultationPopup = () => {
    setIsConsultationVisible(!isConsultationVisible);
  };

  const navigateToQuoteTool = () => {
    navigate("/quote-tool"); // Route to the QuoteTool page
  };
  
  const [activeTestimonial, setActiveTestimonial] = useState(0);

  
  return (
    <div className="landing-page">
      <Header />

      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>AigencyOne Technology</h1>
          <p>
            Leading the way in business systems consolidation, saving companies
            time and money with powerful, integrated solutions.
          </p>
          <div className="hero-buttons">
            <button className="button button-primary" onClick={toggleConsultationPopup}>
              Request Consultation
            </button>
            <button className="button button-outline" onClick={navigateToQuoteTool}>
              Get a Free Quote
            </button>
          </div>
          {/* Stars and Testimonial Section */}
          <div className="hero-testimonial">
            <div className="stars">
              <span>⭐</span>
              <span>⭐</span>
              <span>⭐</span>
              <span>⭐</span>
              <span>⭐</span>
            </div>
            <p className="testimonial-text">
              Trusted by businesses across industries for innovative, consolidated solutions.
            </p>
          </div>
        </div>

        {/* Right Column: Cards */}
        <div className="hero-cards">
          {/* Card 1: Graph */}
          <div className="hero-card card-1">
            <LandingPageGraph className="landing-page-graph" />
          </div>

          {/* Card 2: Project List */}
          <div className="hero-card card-2">
            <h3>Project List</h3>
            <ul>
              <li>Project Alpha</li>
              <li>Beta Campaign</li>
              <li>Gamma Sales Funnel</li>
            </ul>
            <button className="button button-outline">+ Add Project</button>
          </div>

          {/* Card 3: Profile */}
          <div className="hero-card card-3">
            <div className="profile-image-container">
              <img src={LandingPageUserPhoto} alt="Profile Placeholder" className="profile-image" />
            </div>
            <h3>John Davidson</h3>
            <div className="role-tag">Managing Director</div>
            <div className="card-buttons">
              <button className="button button-primary">Call</button>
              <button className="button button-outline">Mail</button>
            </div>
          </div>
        </div>
      </section>

 {/* Consultation Pop-Up */}
 {isConsultationVisible && (
        <ConsultationRequest closePopup={toggleConsultationPopup} />
      )}


      {/* Who We Are Section */}
      <section className="who-we-are">
        <h2 className="section-title">Who We Are</h2>
        <p className="section-subtitle">
          AigencyOne empowers organizations with innovative tools and services
          to streamline operations, secure federal contracts, and foster
          strategic partnerships.
        </p>
        {/* Cards Container */}
        <div className="cards-container">
          <div className="feature-card">
          <img src={PreBuiltAppsIcon} alt="Pre Built Apps Icon" className="feature-icon" />
            <div className="feature-content">
              <h3 className="feature-title">
                Pre-Built Business Admin Management Apps
              </h3>
              <p className="feature-description">
                A ready-made system for managing administrative tasks like
                project management, invoicing, and client management.
              </p>
            </div>
          </div>
          <div className="feature-card">
          <img src={CustomAdminAppsIcon} alt="Custom Admin Apps Icon" className="feature-icon" />
          <div className="feature-content">
              <h3 className="feature-title">
                Custom Business Admin Management Apps
              </h3>
              <p className="feature-description">
                Fully tailored systems for businesses with unique needs,
                designed to optimize workflows and operations.
              </p>
            </div>
          </div>
          <div className="feature-card">
            <img src={SDVOSBIcon} alt="Pairing Icon" className="feature-icon" />
            <div className="feature-content">
              <h3 className="feature-title">
                SDVOSB-Large Firm Pairing Platform
              </h3>
              <p className="feature-description">
                A platform connecting SDVOSBs with large firms to bid on federal
                set-aside contracts.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="trusted-by">
  <h2 className="trusted-by-title">Trusted by Leading Companies</h2>
  <p className="trusted-by-subtitle">
    These companies rely on AigencyOne for their business systems and operational success.
  </p>
  <div className="slider-container">
    <button className="slider-button prev" onClick={handlePrev}>
      &lt;
    </button>
    <div className="slider">
      {[
        {
          logo: EforceLogo,
          color: "#1267e6", 
          url: "https://www.eforcesports.com/",
        },
        {
          logo: GunHavenLogo, // Placeholder for another logo
          color: "#ffffff", 
          url: "https://www.gunhavenpdx.com/", // Replace with actual URL
        },
        {
          logo: CCGLogo, // Placeholder for another logo
          color: "#000000", 
          url: "https://www.centercupgolf.com/", // Replace with actual URL
        },
      ]
        .slice(currentIndex, currentIndex + 3)
        .map((company, index) => (
          <a
            key={index}
            href={company.url}
            target="_blank"
            rel="noopener noreferrer"
            className="slider-card"
            style={{ backgroundColor: company.color }}
          >
            <img
              src={company.logo}
              alt={`${company.name} Logo`}
              className="slider-logo"
            />
            <h3 className="slider-company-name">{company.name}</h3>
            <p className="slider-company-description">{company.description}</p>
          </a>
        ))}
    </div>
    <button className="slider-button next" onClick={handleNext}>
      &gt;
    </button>
  </div>
</section>


{/* Testimonials Section */}
<section className="testimonials">
  <div
    className="testimonial-container"
    style={{
      background: `linear-gradient(135deg, rgba(0, 70, 213, 0.9), rgba(0, 92, 230, 0.8), rgba(0, 120, 255, 0.7)), url(${TestimonialBackground})`,
      backgroundBlendMode: "overlay",
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "2rem",
      borderRadius: "8px",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    }}
  >
    <h2 className="testimonial-title" style={{ color: "#ffffff" }}>
      What People Are Saying
    </h2>
    <div className="testimonial-content">
      {[
        {
          text: `"AigencyOne transformed our workflow with their custom business systems. The AI integration and automation tools saved us countless hours each week. The team was professional, and their expertise was evident in every step of the process."`,
          name: "Sarah Collins",
          position: "CEO at Streamline Consulting",
        },
        {
          text: `"Thanks to AigencyOne, our eCommerce platform now seamlessly integrates with our inventory and CRM systems. This has drastically reduced errors and improved customer satisfaction. Highly recommend their services!"`,
          name: "James Hunter",
          position: "Founder at PeakShop",
        },
        {
          text: `"We struggled with disconnected tools and inefficient workflows before working with AigencyOne. Their system consolidation and custom dashboards brought our team together like never before. Excellent results!"`,
          name: "Emily Reed",
          position: "Operations Manager at NexusTech",
        },
      ].map((testimonial, index) => (
        <div
          key={index}
          className={`testimonial-text-container ${
            activeTestimonial === index ? "active" : "hidden"
          }`}
        >
          <p className="testimonial-text" style={{ color: "#ffffff" }}>
            {testimonial.text}
          </p>
          <h3 className="testimonial-name" style={{ color: "#ffffff" }}>
            {testimonial.name}
          </h3>
          <p className="testimonial-position" style={{ color: "#ffffff" }}>
            {testimonial.position}
          </p>
        </div>
      ))}
    </div>
    {/* Dots for navigation */}
    <div className="testimonial-navigation">
      {[0, 1, 2].map((index) => (
        <span
          key={index}
          className={`dot ${activeTestimonial === index ? "active" : ""}`}
          onClick={() => setActiveTestimonial(index)}
        ></span>
      ))}
    </div>
  </div>
</section>


{/* FAQ Section */}
<section className="faq-section">
  <div className="faq-container">
    <h2 className="section-title">Still Have Questions?</h2>
    <p className="section-subtitle">
      Get answers to some of the most common questions about AigencyOne's custom solutions. If you don't find what you're looking for, feel free to contact us directly!
    </p>
    <div className="faq">
      {[
        {
          question: "What types of custom business systems do you build?",
          answer:
            "We specialize in creating web and mobile applications tailored to your needs. This includes AI chatbot integration, eCommerce development, workflow automation, employee training portals, collaboration platforms, and more."
        },
        {
          question: "Can AigencyOne help consolidate my existing systems?",
          answer:
            "Yes, we excel at system consolidation. We can integrate your current tools and platforms into a unified business system to save time, reduce costs, and enhance efficiency."
        },
        {
          question: "How does AI chatbot integration benefit my business?",
          answer:
            "Our AI chatbots can improve customer support, streamline workflows, and provide 24/7 assistance. They are trained on your business processes and can handle FAQs, data entry, and more, helping you save time and resources."
        },
        {
          question: "What industries do you work with?",
          answer:
            "AigencyOne serves all industries, including construction, healthcare, retail, finance, consulting, and education. Our solutions are tailored to fit the unique needs of any business."
        },
        {
          question: "What is workflow automation, and how can it help my business?",
          answer:
            "Workflow automation streamlines repetitive tasks, such as approvals, notifications, and data synchronization. By automating these processes, your business can improve productivity and reduce manual errors."
        },
        {
          question: "Do you provide data analytics and visualization tools?",
          answer:
            "Absolutely! We create custom dashboards and analytics tools to help you gain actionable insights from your data. These tools allow you to track performance, identify trends, and make informed decisions."
        },
        {
          question: "Can you build an employee training portal for my business?",
          answer:
            "Yes, we develop interactive employee training portals that include video lessons, quizzes, progress tracking, and certifications. These portals ensure efficient onboarding and skill development."
        },
        {
          question: "What collaboration tools do you offer?",
          answer:
            "We build custom collaboration platforms to enhance team communication and project management. These tools can include task management, file sharing, messaging, and video conferencing capabilities."
        },
        {
          question: "How long does it take to develop a custom business system?",
          answer:
            "The timeline varies depending on the complexity of the project. We typically work within a 4-12 week range for most custom solutions, but we'll provide a detailed timeline during the consultation phase."
        },
        {
          question: "What is your process for developing custom solutions?",
          answer:
            "Our process includes a consultation to understand your needs, planning and design, development, testing, and deployment. We also provide ongoing support and updates to ensure the solution grows with your business."
        },
        {
          question: "Can I integrate third-party tools into my custom system?",
          answer:
            "Yes, we can integrate popular third-party tools such as CRMs, accounting software, and marketing platforms into your custom solution for seamless operations."
        },
        {
          question: "What platforms do you develop mobile applications for?",
          answer:
            "We develop mobile applications for both iOS and Android platforms, ensuring your app reaches the widest possible audience."
        },
        {
          question: "How do you ensure the security of my custom business system?",
          answer:
            "We implement robust security measures, including data encryption, authentication protocols, and regular updates to protect your system from threats and ensure compliance with industry standards."
        },
        {
          question: "Do you offer support after deployment?",
          answer:
            "Yes, we offer comprehensive post-deployment support, including maintenance, updates, and additional feature development as your business grows."
        },
        {
          question: "Can I scale my custom solution as my business grows?",
          answer:
            "Absolutely! Our solutions are designed to be scalable, allowing you to add features, users, and integrations as your business expands."
        }
      ].map((faq, index) => (
        <div
          key={index}
          className={`faq-item ${activeFAQ === index ? "active" : ""}`}
          onClick={() => toggleFAQ(index)}
        >
          <div className="faq-question">
            <span className="question-text">{faq.question}</span>
            <span className="toggle-icon">{activeFAQ === index ? "-" : "+"}</span>
          </div>
          {activeFAQ === index && (
            <div className="faq-answer">{faq.answer}</div>
          )}
        </div>
      ))}
    </div>
  </div>
</section>

<Footer />

    </div>
  );
};

export default LandingPage;
