import React from "react";
import "./TermsandConditions.css";
import Footer from "../Global/Footer";

const TermsAndConditions = () => {
  return (
    <div className="terms-page">
      <div className="terms-hero">
        <h1>Terms and Conditions</h1>
      </div>
      <div className="terms-content">
        <section>
          <h2>Introduction</h2>
          <p>
            Welcome to AigencyOne. By accessing or using our services, you agree to
            be bound by these terms and conditions. Please read them carefully.
          </p>
        </section>
        <section>
          <h2>Use of Services</h2>
          <p>
            You agree to use our services only for lawful purposes. Unauthorized
            use, including hacking, fraudulent activities, or misuse of our
            platform, is strictly prohibited.
          </p>
        </section>
        <section>
          <h2>Privacy Policy</h2>
          <p>
            Your use of our services is also governed by our Privacy Policy,
            which outlines how we collect, use, and protect your information.
          </p>
        </section>
        <section>
          <h2>Limitation of Liability</h2>
          <p>
            AigencyOne is not responsible for any indirect, incidental, or
            consequential damages arising from your use of our services.
          </p>
        </section>
        <section>
          <h2>Changes to Terms</h2>
          <p>
            We reserve the right to update these terms at any time. Changes will
            be effective immediately upon posting.
          </p>
        </section>
        <section>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about these terms, please contact us at
            support@aigencyone.com.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;