import React from "react";
import "./Footer.css";
import logo from "../../assets/logo.svg"; // Replace with the correct logo path

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        {/* Logo and Subscription Section */}
        <div className="footer-left">
          <img src={logo} alt="Logo" className="footer-logo" />
          <p className="footer-prompt">Ready to try?</p>
          <div className="subscription-form">
            <input
              type="email"
              placeholder="Enter your mail"
              className="subscription-input"
            />
            <button className="subscription-button">
              <span>&#8594;</span>
            </button>
          </div>
        </div>

        {/* Links Section */}
        <div className="footer-links">
          <div className="link-column">
            <a href="/services">Services</a>
            <a href="/pricing">Pricing</a>
            <a href="/about-us">About Us</a>
          </div>
          <div className="link-column">
            <a href="/quote-tool">Quote</a>
            <a href="/financing">Financing</a>
          </div>
          <div className="link-column">
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/terms-of-service">Terms of Service</a>
            <a href="/contact-us">Contact Us</a>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>© 2024 AigencyOne Inc. All rights reserved.</p>
        <div className="social-icons">
          <a href="https://facebook.com" aria-label="Facebook" className="social-icon">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://instagram.com" aria-label="Instagram" className="social-icon">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://twitter.com" aria-label="X (Twitter)" className="social-icon">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://youtube.com" aria-label="YouTube" className="social-icon">
            <i className="fab fa-youtube"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
