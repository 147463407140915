import React from "react";
import "./AboutUs.css";
import Header from "../Global/Header";
import Footer from "../Global/Footer";
import MeetTheOwner from "../../assets/MeetTheOwner.png";

const AboutUs = () => {
  return (
    <div className="about-us-page">
      <Header />
      <div className="about-us-hero-background">
        <div className="container">
          <div className="about-us-hero-content">
            <h1 className="about-us-title">About Us</h1>
            <p className="about-us-mission">
              At AigencyOne, our mission is to empower businesses with
              innovative technology solutions that streamline operations and
              drive growth. As a Service-Disabled Veteran-Owned Small Business
              (SDVOSB), we are proud to deliver excellence while fostering a
              culture of resilience, innovation, and community.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <section className="about-us-values-section">
          <h2 className="section-title">Our Core Values</h2>
          <ul className="values-list">
            <li>
              <strong>Innovation:</strong> Driven by creativity and the pursuit
              of transformative technology solutions.
            </li>
            <li>
              <strong>Resilience:</strong> Facing challenges with determination
              and turning obstacles into opportunities for growth.
            </li>
            <li>
              <strong>Leadership:</strong> Building meaningful connections and
              empowering others through technology and collaboration.
            </li>
          </ul>
        </section>

        <section className="about-us-meet-the-owner">
          <h2 className="section-title">Meet the Owner</h2>
          <p className="meet-the-owner-description">
            Marcus Campbell, founder of AigencyOne, combines his passion for
            technology with his military background to deliver innovative
            solutions for businesses of all sizes. Marcus’s commitment to
            excellence and community is at the heart of everything we do.
          </p>
          <div className="meet-the-owner-flyer">
            <img
              src={MeetTheOwner}
              alt="Meet the Owner Flyer"
              className="meet-the-owner-image"
            />
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
