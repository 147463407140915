import React, { useState } from "react";
import "../Products/Products.css";
import Header from "../Global/Header";
import Footer from "../Global/Footer";
import CustomBusinessSystems from "../../assets/CustomBusinessSystems.png"; // Placeholder image
import WorkflowAutomation from "../../assets/WorkflowAutomation.png"; // Placeholder image
import AIChatbot from "../../assets/AIChatbot.png"; // Placeholder image
import EcommerceDevelopment from "../../assets/EcommerceDevelopment.png"; // Placeholder image
import EmployeeTraining from "../../assets/EmployeeTraining.png"; // Placeholder image
import DataVisualization from "../../assets/DataVisualization.png"; // Placeholder image
import SystemConsolidation from "../../assets/SystemConsolidation.png"; // Placeholder image
import CollaborationPlatform from "../../assets/CollaborationPlatform.png"; // Placeholder image
import { Link } from 'react-router-dom';



// Complete services list with detailed descriptions
const services = [
  {
    id: 1,
    name: "Custom Business Systems",
    description: "Tailored web and mobile applications to streamline and enhance your business operations.",
    details: {
      overview:
        "Our custom business systems provide tailored solutions to meet your specific business needs. Whether it's a web or mobile application, we design scalable systems to streamline processes and increase efficiency.",
      features: [
        "Custom-built web and mobile apps",
        "Seamless integration with existing tools",
        "Scalable architecture for business growth",
        "Ongoing support and maintenance",
      ],
      benefits: [
        "Boosts operational efficiency",
        "Tailored to your unique business needs",
        "Future-proof and scalable",
      ],
    },
    tags: ["Web Applications", "Mobile Applications", "Custom Solutions"],
    image: CustomBusinessSystems,
  },
  {
    id: 2,
    name: "AI Chatbot Integration",
    description: "Integrate AI-powered chatbots for enhanced customer engagement and support.",
    details: {
      overview:
        "AI Chatbot Integration helps businesses deliver efficient and personalized customer service. Leverage AI technology to answer queries, gather feedback, and streamline support.",
      features: [
        "Natural language processing (NLP)",
        "Integration with your existing systems",
        "24/7 customer service automation",
        "Multi-language support",
      ],
      benefits: [
        "Improves customer engagement",
        "Reduces workload for your team",
        "Enhances support availability",
      ],
    },
    tags: ["AI", "Chatbot Integration"],
    image: AIChatbot,
  },
  {
    id: 3,
    name: "E-commerce Development",
    description: "Develop robust e-commerce platforms with secure payment gateways and advanced features.",
    details: {
      overview:
        "Our e-commerce development service builds platforms that combine sleek design with robust functionality. Enable seamless transactions and provide a better user experience for your customers.",
      features: [
        "Custom e-commerce platform design",
        "Secure payment gateway integration",
        "Product catalog and inventory management",
        "User-friendly interfaces",
      ],
      benefits: [
        "Increases online sales",
        "Offers seamless user experience",
        "Ensures secure transactions",
      ],
    },
    tags: ["E-commerce", "Web Applications", "Mobile Applications"],
    image: EcommerceDevelopment,
  },
  {
    id: 4,
    name: "Workflow Automation",
    description: "Automate repetitive tasks to boost productivity and save time.",
    details: {
      overview:
        "Workflow Automation simplifies repetitive tasks and reduces manual work. Focus on what matters while automation takes care of the rest.",
      features: [
        "Automated task scheduling",
        "Integration with third-party platforms",
        "Real-time updates and notifications",
        "Custom workflow rules",
      ],
      benefits: [
        "Saves time by reducing manual tasks",
        "Increases productivity",
        "Ensures consistent and accurate results",
      ],
    },
    tags: ["Automation", "Workflow Optimization"],
    image: WorkflowAutomation,
  },
  {
    id: 5,
    name: "System Consolidation",
    description: "Unify your existing tools into a single integrated platform.",
    details: {
      overview:
        "System Consolidation reduces complexity by integrating your tools into one cohesive platform. Manage your data and processes from a centralized hub.",
      features: [
        "Centralized platform for all tools",
        "Data synchronization across systems",
        "Customizable dashboards",
        "Reduced software redundancy",
      ],
      benefits: [
        "Streamlines operations",
        "Improves data accuracy",
        "Reduces costs by eliminating unnecessary tools",
      ],
    },
    tags: ["System Integration", "Custom Solutions"],
    image: SystemConsolidation,
  },
  {
    id: 6,
    name: "Data Analytics & Visualization",
    description: "Gain insights with custom analytics dashboards and visualization tools.",
    details: {
      overview:
        "Transform raw data into actionable insights. Our data analytics service includes custom dashboards and visualization tools to help you make informed decisions.",
      features: [
        "Custom analytics dashboards",
        "Advanced visualization tools",
        "Real-time data updates",
        "Predictive analytics capabilities",
      ],
      benefits: [
        "Enables data-driven decision-making",
        "Improves visibility into business performance",
        "Helps identify growth opportunities",
      ],
    },
    tags: ["Analytics", "Visualization"],
    image: DataVisualization,
  },
  {
    id: 7,
    name: "Employee Training Portals",
    description: "Interactive training portals to onboard employees and enhance skill development.",
    details: {
      overview:
        "Our Employee Training Portals provide an interactive way to onboard new hires and upskill your workforce. Access comprehensive training materials in one platform.",
      features: [
        "Customizable training modules",
        "Progress tracking and reporting",
        "Integration with HR systems",
        "Interactive quizzes and activities",
      ],
      benefits: [
        "Enhances employee performance",
        "Simplifies onboarding processes",
        "Tracks employee progress",
      ],
    },
    tags: ["Training", "Onboarding"],
    image: EmployeeTraining,
  },
  {
    id: 8,
    name: "Collaboration Platforms",
    description: "Centralized platforms for seamless team collaboration and communication.",
    details: {
      overview:
        "Collaboration Platforms make teamwork more efficient. Manage tasks, share files, and communicate effectively—all in one place.",
      features: [
        "Shared task boards",
        "Integrated communication tools",
        "File sharing and version control",
        "Team activity tracking",
      ],
      benefits: [
        "Increases team productivity",
        "Streamlines communication",
        "Reduces tool-switching fatigue",
      ],
    },
    tags: ["Collaboration", "Workflow Optimization"],
    image: CollaborationPlatform,
  },
];

const Services = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [activeService, setActiveService] = useState(null);

  const filteredServices = services.filter((service) => {
    const matchesSearch = service.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTag = selectedTag ? service.tags.includes(selectedTag) : true;
    return matchesSearch && matchesTag;
  });

  const closeModal = () => {
    setActiveService(null);
  };

    return (
        <div className="products-page">
          <Header />
          <div className="products-hero-background"></div>
          <div className="products-content-container">
            <div className="products-header">
              <h1 className="products-title">Our Services</h1>
              <p className="products-description">
                Explore our services designed to transform your business operations and drive efficiency.
              </p>
              <Link to="/quote-tool" className="products-signup-button">
      Request Quote
    </Link>            </div>
    
            <div className="products-filter-container">
              <input
                type="text"
                placeholder="Search services..."
                className="products-search-bar"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <select
                className="products-tag-filter"
                onChange={(e) => setSelectedTag(e.target.value === "All" ? "" : e.target.value)}
              >
                <option value="All">All</option>
                {[
                  "Web Applications",
                  "Mobile Applications",
                  "Custom Solutions",
                  "AI",
                  "Chatbot Integration",
                  "E-commerce",
                  "Automation",
                  "Workflow Optimization",
                  "Analytics",
                  "Visualization",
                  "Training",
                  "Onboarding",
                  "Collaboration",
                  "System Integration",
                ].map((tag) => (
                  <option key={tag} value={tag}>
                    {tag}
                  </option>
                ))}
              </select>
            </div>
    
            <div className="products-container">
              {filteredServices.map((service) => (
                <div className="products-card" key={service.id}>
                  <img src={service.image} alt={service.name} className="products-image" />
                  <h3 className="products-name">{service.name}</h3>
                  <p className="products-description">{service.description}</p>
                  <button
                    className="products-learn-more"
                    onClick={() => setActiveService(service)}
                  >
                    Learn More
                  </button>
                </div>
              ))}
            </div>
    
            {activeService && (
              <div className="modal">
                <div className="modal-content">
                  <h2>{activeService.name}</h2>
                  <p>{activeService.details.overview}</p>
                  <h3>Features:</h3>
                  <ul>
                    {activeService.details.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                  <h3>Benefits:</h3>
                  <ul>
                    {activeService.details.benefits.map((benefit, index) => (
                      <li key={index}>{benefit}</li>
                    ))}
                  </ul>
                  <Link to="/consultation-request" className="products-get-started">
  Get Started
</Link>
                  <button className="modal-close" onClick={closeModal}>
                    ×
                  </button>
                </div>
              </div>
            )}
          </div>
      <Footer />
    </div>
  );
};

export default Services;
